
.section-cloud{

    .cloud-container{
        // position: relative;
        max-width: 100%;
        height: 400px;
        max-height: 400px;
        margin: 0 auto;
        font-size: $font-size-h4;
        font-weight: 500;
        display: grid;
        // //   grid-gap: 10px;
          grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
          justify-items: center;
          text-align: center;


       
    //     .wrapper {
    //   display: grid;
    // //   grid-gap: 10px;
    //   grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    // //   grid-template-rows: repeat(3, 150px);
    // }
    // .wrapper > div {
    //   padding: 10px;
    //   background-color: #EFCB68;
    //   border-radius: 5px;
    //   display: grid;
    //   place-items: center;
    // }

    // .wrapper > div {
    //   font-family: sans-serif;
    //   font-size: 24px;
    //   font-weight: bold;
    // }
          
        .cloud-bold{
            font-weight: 800;
            color:#F2B15D;
        }

        i{
            font-size: calc(10px + 1vw);
            position: absolute;
            top: 0;
            left: 0;

            &:nth-child(1){
                top: 5%;
                left: 7%;
            }
            &:nth-child(2){
                top: 25%;
                left: 24%;
            }
            &:nth-child(3){
                top: 30%;
            }
            &:nth-child(4){
                top: 10%;
                left: 80%;
            }
            &:nth-child(5){
                top: 100%;
                left: 0%;
            }
            &:nth-child(6){
                top: 40%;
                left: 30%;
                font-size: 3vw;
                color: $danger-color;
                padding: 1px;
            }
            &:nth-child(7){
                top: 65%;
                left: 22%;
            }
            &:nth-child(8){
                top: 50%;
                left: 75%;
            }
            &:nth-child(9){
                top: 85%;
                left: 60%;
            }
        }
    }
    // .cloud-container > *{
    //     border: 1px solid black; /* try border-left and border-right */
    // }
}
.section-dark{
    .cloud-container{
        color: $white-color;
    }
}

.section-nucleo-icons{
    padding: 100px 0;

    .icons-container{
        position: relative;
        max-width: 450px;
        height: 300px;
        max-height: 300px;
        margin: 0 auto;

        i{
            font-size: 34px;
            position: absolute;
            top: 0;
            left: 0;

            &:nth-child(1){
                top: 5%;
                left: 7%;
            }
            &:nth-child(2){
                top: 28%;
                left: 24%;
            }
            &:nth-child(3){
                top: 40%;
            }
            &:nth-child(4){
                top: 18%;
                left: 62%;
            }
            &:nth-child(5){
                top: 74%;
                left: 3%;
            }
            &:nth-child(6){
                top: 36%;
                left: 44%;
                font-size: 65px;
                color: $danger-color;
                padding: 1px;
            }
            &:nth-child(7){
                top: 59%;
                left: 26%;
            }
            &:nth-child(8){
                top: 60%;
                left: 69%;
            }
            &:nth-child(9){
                top: 72%;
                left: 47%;
            }
            &:nth-child(10){
                top: 88%;
                left: 27%;
            }
            &:nth-child(11){
                top: 31%;
                left: 80%;
            }
            &:nth-child(12){
                top: 88%;
                left: 68%;
            }
            &:nth-child(13){
                top: 5%;
                left: 81%;
            }
            &:nth-child(14){
                top: 58%;
                left: 90%;
            }
            &:nth-child(15){
                top: 6%;
                left: 40%;
            }
        }
    }
}
.section-dark{
    .icons-container{
        color: $white-color;
    }
}
.info{
    max-width: 100%;
    .icon{
        margin-top: 0;
        font-size: 3.4em;
    }
}
.icon-primary{
    color: $primary-color;
}
.icon-info{
    color: $info-color;
}
.icon-success{
    color: $success-color;
}
.icon-warning{
    color: $warning-color;
}
.icon-danger{
    color: $danger-color;
}
.icon-neutral{
    color: $white-color;
}
